import moment from 'moment'
import _ from 'lodash'
import { COLORS } from 'assets/styles/globalStyles'

export const SEARCHABLE_DROPDOWN_OPTIONS = {
  showSearch: true,
  optionFilterProp: 'children',
  filterOption: (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}

export const customCapitalizeWords = str => {
  if (str === '' || str === undefined || str === null) return ''

  return (
    str
      .toLowerCase()
      .split('_')
      // eslint-disable-next-line func-names
      .map(function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join('_')
  )
}

export const downloadXmlFile = (xmlString, fileName) => {
  const formattedXml = formatXml(xmlString, 2)

  // Create a Blob from the formatted XML content
  const blob = new Blob([formattedXml], { type: 'application/xml' })

  // Create a link element
  const link = document.createElement('a')

  // Set the download attribute and the file name
  link.download = `${fileName}.anvil` || 'formattedXmlFile.anvil'

  // Create a URL for the Blob and set it as the link's href
  link.href = window.URL.createObjectURL(blob)

  // Append the link to the document
  document.body.appendChild(link)

  // Trigger a click on the link to start the download
  link.click()

  // Remove the link element from the document
  document.body.removeChild(link)
}

export const timeStringToMilliseconds = timeString => {
  const parts = timeString.split(':')
  const hours = parseFloat(parts[0]) * 3600 // 1 hour = 3600000 milliseconds
  const minutes = parseFloat(parts[1]) * 60 // 1 minute = 60000 milliseconds
  let seconds = 0

  if (parts[2]) {
    const secondsParts = parts[2].split('.')
    seconds = parseFloat(secondsParts[0]) // Seconds part
    if (secondsParts[1]) {
      // eslint-disable-next-line prefer-template
      const milliseconds = parseFloat('0.' + secondsParts[1])
      seconds += milliseconds
    }
  }

  return hours + minutes + seconds
}

export const formatXml = xmlString => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(xmlString, 'application/xml')
  const serializer = new XMLSerializer()
  const formatted = serializer.serializeToString(xmlDoc)

  return formatted.replace(/></g, '>\n<') // Add line breaks between tags
}

const getNum = num => num.toString().padStart(2, 0)

export const formatTime = seconds => {
  if (seconds === 0) {
    return '00:00:00'
  }
  const sec = moment.duration(seconds, 'seconds').seconds()
  const min = moment.duration(seconds, 'seconds').minutes()
  const hr = moment.duration(seconds, 'seconds').hours()

  return `${getNum(hr)}:${getNum(min)}:${getNum(sec)}`
}

export const convertString = str => {
  const temp = _.split(str, '_')
  const join = _.join(
    temp.map(item => _.capitalize(item)),
    ' ',
  )
  return join
}

export const getAnnotationStatusColor = status => {
  if (status === 'PENDING') return COLORS.orange
  if (status === 'VERIFIED_READY_FOR_MLT') return COLORS.success
  if (status === 'DELETE') return COLORS.danger
  return 'black'
}

export const getAnnotationStatusText = status => {
  if (status === 'PENDING') return 'Pending'
  if (status === 'VERIFIED_READY_FOR_MLT') return 'Verified'
  if (status === 'DELETE') return 'Delete'
  return ''
}
export const getAnnotationStatusFromValue = status => {
  if (status === 'pending') return 'PENDING'
  if (status === 'verified-ready-for-MLT') return 'VERIFIED_READY_FOR_MLT'
  if (status === 'delete') return 'DELETE'
  return ''
}

export const utcToLocal = utcDateTime => {
  const utcMoment = moment.utc(utcDateTime)

  // Convert to local time
  const localMoment = utcMoment.local()

  // Format the local time (optional)
  const formattedLocalTime = localMoment.format('YYYY-MM-DD HH:mm:ss.SSS')

  // Update the state with the formatted local time
  return formattedLocalTime
}

export const calculateMonthRanges = dateRange => {
  const start = new Date(dateRange.start)
  const end = new Date(dateRange.end)
  const monthRanges = []

  let currentMonthStart = new Date(start)

  while (currentMonthStart < end) {
    const currentMonthEnd = new Date(currentMonthStart)
    currentMonthEnd.setMonth(currentMonthEnd.getMonth() + 1)
    currentMonthEnd.setDate(0) // Set to the last day of the month

    const rangeEnd = currentMonthEnd < end ? currentMonthEnd : end

    monthRanges.push({
      startDate: formatDate(currentMonthStart),
      endDate: formatDate(rangeEnd),
    })

    currentMonthStart = new Date(currentMonthEnd)
    currentMonthStart.setDate(currentMonthEnd.getDate() + 1)
  }

  return monthRanges
}

export const formatDate = date => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const calculateWeeks = dateRange => {
  const start = new Date(dateRange.start)
  const end = new Date(dateRange.end)
  const weekRanges = []

  let currentWeekStart = new Date(start)

  while (currentWeekStart <= end) {
    const currentWeekEnd = new Date(currentWeekStart)
    currentWeekEnd.setDate(currentWeekEnd.getDate() + (7 - currentWeekEnd.getDay())) // Set to the end of the week

    const rangeEnd = currentWeekEnd < end ? currentWeekEnd : end

    weekRanges.push({
      startDate: formatDate(currentWeekStart),
      endDate: formatDate(rangeEnd),
    })

    currentWeekStart = new Date(rangeEnd)
    currentWeekStart.setDate(currentWeekStart.getDate() + 1)
  }

  return weekRanges
}

export const getDatesBetween = (startDate, endDate) => {
  const dates = []
  const currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

export const getNameArrayByIds = (data, selectedIds = []) => {
  return data.filter(item => selectedIds.includes(item.id)).map(item => item.name)
}

export const mapArrayToString = array => {
  return `${array.length ? `${array.map(item => item.node.name).join(', ')}` : ''}`
}

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    // eslint-disable-next-line prefer-template
    return text.substring(0, maxLength) + '...'
  }
  return text
}

export const getListOfIds = jsonResponse => {
  if (!jsonResponse || !jsonResponse.edges || !Array.isArray(jsonResponse.edges)) {
    // Handle invalid or missing data
    return []
  }

  // Extract IDs from the 'edges' array
  const ids = jsonResponse.edges.map(edge => {
    // Check if 'node' property exists and has an 'id' property
    if (edge && edge.node && edge.node.id) {
      return edge.node.id
    }
    return null // Return null for invalid or missing data
  })

  // Filter out any null values
  return ids.filter(id => id !== null)
}
